import React from 'react'
import { Link } from 'gatsby'

export default ({ siteTitle }) => (
  <header className="tufte">
    <Link to="/">{siteTitle}</Link>
    <nav>
      <ul className="header-links">
        <li className="header-links__link">
          <Link to="/blog">Blog</Link>
        </li>
        <li className="header-links__link">
          <Link to="/tags">Tags</Link>
        </li>
        <li className="header-links__link">
          <Link to="/talks">Talks</Link>
        </li>
      </ul>
    </nav>
  </header>
)
