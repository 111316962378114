import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from './header.js'
import SEO from './seo.js'
import 'prismjs/themes/prism.css'
import '../css/layout.css'
import '../css/tufte.css'
import '../css/constants.css'

const Layout = ({ children, ...rest }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <React.Fragment>
        <SEO {...rest} />
        <Header siteTitle={data.site.siteMetadata.title} />
        <main className="tufte">{children}</main>
      </React.Fragment>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default Layout
