import React from 'react'

import Layout from '../components/layout'

export default function Home() {
  return (
    <Layout title="Home">
      <article className="h-card">
        <h1>About me</h1>

        <p>
          I'm <span className="p-name">Julien Tanguy</span> a web developer at{' '}
          <a href="https://www.sfeir.com" className="p-org h-card">
            Sfeir
          </a>
          . I am quite fond of functional programming and languages such as
          Haskell and Elm.
        </p>

        <p>I also code in Scala and NodeJS in my work time.</p>

        <p>Here are some links to find me on the internet.</p>

        <ul>
          <li>
            Twitter:{' '}
            <a href="https://twitter.com/jutanguy" rel="me">
              @jutanguy
            </a>
          </li>
          <li>
            Github: <a href="https://github.com/jtanguy">jtanguy</a>
          </li>
          <li>
            Keybase: <a href="https://keybase.io/jtanguy">jtanguy</a>
          </li>
          <li>
            LinkedIn:{' '}
            <a href="https://www.linkedin.com/in/jutanguy" rel="me">
              jutanguy
            </a>
          </li>
        </ul>
      </article>
    </Layout>
  )
}
